import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import docimage from '../assets/doc.png';
import { GoCheckCircleFill } from 'react-icons/go';
import { AiFillCloseCircle } from 'react-icons/ai';
import { Button,Modal ,Table} from 'antd';
import config from '../config/config';

const Dashboard = () => {
  const [patients, setPatients] = useState([]);
  const [totalAppointments, setTotalAppointments] = useState(0);
  const [totalVisitedPatients, setTotalVisitedPatients] = useState(0);
  const [currentDoctor, setCurrentDoctor] = useState(null);
  const [selectedAppointment, setSelectedAppointment] = useState(null); // To track the appointment for which action is performed
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    const fetchCurrentDoctor = async () => {
      try {
        const { data } = await axios.get(`${config.API_URL}/api/doctor/currentdoctor`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setCurrentDoctor(data.doctor);
        return data.doctor._id;
      } catch (error) {
        toast.error('Failed to fetch doctor information');
        throw error;
      }
    };

    const fetchPatients = async (doctorId) => {
      try {
        const { data } = await axios.get(`${config.API_URL}/api/patient/patients/doctor/${doctorId}`);
        setPatients(data.patients);

        // Calculate total appointments and visited patients
        const totalAppointments = data.patients.reduce((acc, patient) => acc + patient.appointmentTimes.length, 0);
        const totalVisitedPatients = data.patients.reduce((acc, patient) => acc + patient.appointmentTimes.filter(appointment => appointment.hasVisited).length, 0);

        setTotalAppointments(totalAppointments);
        setTotalVisitedPatients(totalVisitedPatients);
      } catch (error) {
        console.error('Error fetching patients:', error);
        setPatients([]);
      }
    };

    const initializeDashboard = async () => {
      try {
        const doctorId = await fetchCurrentDoctor();
        await fetchPatients(doctorId);
      } catch (error) {
        console.error('Initialization failed:', error);
      }
    };

    initializeDashboard();
  }, []);

  // const handleUpdateStatus = async (appointmentId, status) => {
  //   try {
  //     const { data } = await axios.put(
  //       `http://localhost:4000/api/v1/appointment/update/${appointmentId}`,
  //       { status },
  //       { withCredentials: true }
  //     );
  //     setPatients((prevPatients) =>
  //       prevPatients.map((patient) => ({
  //         ...patient,
  //         appointmentTimes: patient.appointmentTimes.map((appointment) =>
  //           appointment._id === appointmentId ? { ...appointment, status } : appointment
  //         ),
  //       }))
  //     );
  //     toast.success(data.message);
  //   } catch (error) {
  //     toast.error(error.response.data.message);
  //   }
  // };

  const handleCancelAppointment = async (appointmentId) => {
    try {
      const { data } = await axios.put(
        `${config.API_URL}/api/patient/cancel/${appointmentId}`,
        { status: 'Canceled' }, // Update status to 'Canceled'
        { withCredentials: true }
      );
      setPatients((prevPatients) =>
        prevPatients.map((patient) => ({
          ...patient,
          appointmentTimes: patient.appointmentTimes.map((appointment) =>
            appointment._id === appointmentId ? { ...appointment, status: 'Canceled' } : appointment
          ),
        }))
      );
      toast.success(data.message);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleAddMinutes = async (appointmentId, minutesToAdd) => {
    try {
      const { data } = await axios.put(
        `${config.API_URL}/api/patient/addMinutes/${appointmentId}`,
        { minutesToAdd }, // Send minutes to add to the backend
        { withCredentials: true }
      );
  
      setPatients((prevPatients) =>
        prevPatients.map((patient) => {
          const updatedAppointments = patient.appointmentTimes.map((appointment) => {
            if (appointment._id === appointmentId && appointment.endTime instanceof Date) {
              // Ensure appointment.endTime is a Date object
              const newEndTime = new Date(appointment.endTime.getTime() + minutesToAdd * 60000);
              return { ...appointment, endTime: newEndTime };
            }
            return appointment;
          });
  
          return { ...patient, appointmentTimes: updatedAppointments };
        })
      );
  
      toast.success(data.message);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  

  const handleFinishAppointment = async (appointmentId) => {
    try {
      const { data } = await axios.put(
        `${config.API_URL}/api/patient/finish/${appointmentId}`,

        { status: 'Finished' }, // Update status to 'Finished'
        { withCredentials: true }
      );
      console.log(data);
      setPatients((prevPatients) =>
        prevPatients.map((patient) => ({
          ...patient,
          appointmentTimes: patient.appointmentTimes.map((appointment) =>
            appointment._id === appointmentId ? { ...appointment, status: 'Canceled' } : appointment
          ),
        }))
      );
      toast.success(data.message);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleSecondAppointment = (appointment) => {
    setSelectedAppointment(appointment);
    setModalVisible(true); // Show the modal when selecting an appointment
  };
  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const columns = [
    {
      title: 'Patient',
      dataIndex: 'fullname',
      key: 'fullname',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Start/End Time',
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, appointment) => (
        <>
          <Button onClick={() => handleAddMinutes(appointment._id, 15)} style={{ marginRight: '8px', backgroundColor: '#16a34a', color: 'white', border: 'none', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', padding: '8px 16px' }}>Add 15 Min</Button>
          <Button onClick={() => handleSecondAppointment(appointment)} style={{ marginRight: '8px', backgroundColor: '#003261', color: 'white', border: 'none', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', padding: '8px 16px' }}>Second Appointment</Button>
          {appointment.status !== 'Canceled' && (
            <>
              <Button onClick={() => handleCancelAppointment(appointment._id)} style={{ marginRight: '8px', backgroundColor: 'red', color: 'white', border: 'none', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', padding: '8px 16px' }}>Cancel</Button>
              <Button onClick={() => handleFinishAppointment(appointment._id)} style={{ backgroundColor: '#0094ff', color: 'white', border: 'none', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', padding: '8px 16px' }}>Finish</Button>
            </>
          )}
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
  ];

  const data = patients.flatMap((patient) =>
    patient.appointmentTimes.map((appointment) => ({
      key: appointment._id,
      fullname: patient.fullname,
      date: new Date(appointment.startTime).toLocaleDateString(),
      time: `${new Date(appointment.startTime).toLocaleTimeString()} - ${new Date(appointment.endTime).toLocaleTimeString()}`,
      status: appointment.status,
    }))
  );

  return (
    <section className="dashboard page">
      <div className="banner">
        <div className="firstBox">
          <img src={docimage} alt="docImg" />
          <div className="content">
            <div>
              <p style={{fontWeight:'bold'}}>Hello,</p>
              <h5 style={{paddingTop:'15px',fontSize:'1.6rem'}}>{currentDoctor && currentDoctor.fullname}</h5>
            </div>
            <p className="head_txt">
              We are delighted to extend a warm welcome to you. Your dedication to patient care and commitment to excellence in the medical profession are truly inspiring.
            </p>
          </div>
        </div>
        <div className="secondBox">
          <p>Total Appointments</p>
          <h3>{totalAppointments}</h3>
        </div>
        {/* <div className="thirdBox">
          <p>Total Visited Patients</p>
          <h3>{totalVisitedPatients}</h3>
        </div> */}
      </div>
      <div className="banner">
        <h5 style={{fontWeight:'bold'}}>Appointments</h5>
        {/* <table>
          <thead>
            <tr>
              <th>Patient</th>
              <th>Date</th>
              <th>Start/End Time</th>
              <th>Actions</th>
              <th>Status</th>
              
            </tr>
          </thead>
          <tbody>
            {patients.length > 0 ? (
              patients.flatMap((patient) =>
                patient.appointmentTimes.map((appointment) => (
                  <tr key={appointment._id}>
                    <td>{patient.fullname}</td>
                    <td>{new Date(appointment.startTime).toLocaleDateString()}</td>
                    <td>
                      {new Date(appointment.startTime).toLocaleTimeString()} - {new Date(appointment.endTime).toLocaleTimeString()}
                    </td>
                    <td>
                      <Button onClick={() => handleAddMinutes(appointment._id, 15)} style={{ marginRight: '8px', backgroundColor: '#16a34a', color: 'white',  border: 'none', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', padding: '8px 16px', }}>Add 15 Min</Button>
                      <Button onClick={() => handleSecondAppointment(appointment)} style={{ marginRight: '8px' , backgroundColor: '#003261', color: 'white',  border: 'none', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', padding: '8px 16px',}}>Second Appointment</Button>
                      {appointment.status !== 'Canceled' && (
                        <>
                          <Button onClick={() => handleCancelAppointment(appointment._id)} style={{ marginRight: '8px', backgroundColor: 'red', color: 'white', border: 'none', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', padding: '8px 16px' }}>Cancel</Button>
                          <Button onClick={() => handleFinishAppointment(appointment._id)} style={{ backgroundColor: '#0094ff', color: 'white', border: 'none', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', padding: '8px 16px' }}>Finish</Button>
                        </>
                      )}
                    </td>
                    <td>{appointment.status}</td>
                    <Modal
                      title="Schedule Second Appointment"
                      visible={modalVisible}
                      onCancel={handleCloseModal}
                      footer={[
                        <Button key="cancel" onClick={handleCloseModal}>
                          Cancel
                        </Button>,
                       
                      ]}
                    >
                      <p>{`Doctor: ${selectedAppointment?.doctorId.fullname}`}</p>
                      <p>{`Start Time: ${new Date(selectedAppointment?.startTime).toLocaleString()}`}</p>
                      
                    </Modal>
                   
                  </tr>
                ))
              )
            ) : (
              <tr>
                <td colSpan="6">No Appointments Found!</td>
              </tr>
            )}
          </tbody>
        </table> */}
           <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          locale={{
            emptyText: 'No Appointments Found!',
          }}
        />
      </div>
    </section>
  );
};

export default Dashboard;

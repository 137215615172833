import React from "react";
import { Link } from "react-router-dom";
import { FaLocationArrow, FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import iconimg from '../../src/assets/Whitelogo.png'


const Footer = () => {
  

  return (
    <>
<div className="container_footer" style={{background: "linear-gradient(45deg, #0094ff, #003261)",  borderRadius: "15px",
  margin: "6px 13px 0px 13px"}}>
<footer className={"container justify-content-center"} >
        
        <div className="content " style={{paddingTop:'50px'}}>
            <div>
            <a href="/" >
            <img src={iconimg}  width="280" height="80" alt="logo" className="logo-img" style={{marginTop:'40px'}}/>
            
          </a>
            </div>
     
          <div>
            <h4>Quick Links</h4>
            <ul>
                
              <Link to={"/"}>Home</Link>
              {/* <Link to={"/appointment"}>Login</Link> */}
              <Link to={"/login"}>Logout</Link>
            </ul>
          </div>

          <div>
            <h4>Contact</h4>
            <div style={{color:'white',fontSize:'15px'}}>
              <FaPhone />
              <span>999-999-9999</span>
            </div>
            <div style={{color:'white',fontSize:'15px'}}>
              <MdEmail />
              <span>zeelab@gmail.com</span>
            </div>
            <div style={{color:'white',fontSize:'15px'}}>
              <FaLocationArrow />
              <span>Karachi</span>
            </div>
          </div>
        </div>
      </footer>
</div>

    </>
  );
};

export default Footer;

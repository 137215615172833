// import React from 'react'

// import { useNavigate } from 'react-router-dom';
// import iconimg from '../../src/assets/Whitelogo.png'


// import '../index.css'


// export default function Navbar() {


// const navigate = useNavigate()
  
//   return (
    
//     <div>
//       <nav class="navbar navbar-expand-lg navbar-light bg-light box_shadow_4">
//   <div class="container-fluid">
//   {/* <img className= 'logoimage'src={iconimg} style={{}}/> */}

//   <a className="navbar-brand d-flex align-items-center" href="/">
//             <img src={iconimg} alt="Logo" width="170" height="50" style={{ marginRight: '10px' }} /> {/* Display the logo */}
          
//       </a>
//     <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
//       <span class="navbar-toggler-icon"></span>
//     </button>
//     <div class="collapse navbar-collapse" id="navbarSupportedContent">
//       <ul class="navbar-nav ">
    

//         {/* <li class="nav-item">
//           <a class="nav-link active" aria-current="page" href="/table">Appointment</a>
//         </li>
//          */}
//         {/* <li class="nav-item">
//           <a class="nav-link active" aria-current="page" href="/register">Register</a>
//         </li> */}
//         <li class="nav-item">
//           <a class="nav-link active" aria-current="page" href="/login">Login</a>
//         </li>
    
//       </ul>
  
//     </div>
//   </div>
// </nav>
//     </div>
//   )
// }


import React, { useState, useEffect } from 'react';
import { Link, useLocation,useNavigate } from 'react-router-dom';
import iconimg from '../../src/assets/Whitelogo.png';
import '../index.css';
import { Button } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';


const Navbar = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Track login state

  useEffect(() => {
    // Check if user is logged in based on token presence in localStorage
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const handleLogout = () => {
    // Clear token from localStorage and update login state
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    navigate('/login');
  };

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light box_shadow_4">
        <div className="container-fluid">
          <a className="navbar-brand d-flex align-items-center" href="/">
            <img src={iconimg} alt="Logo" width="170" height="50" style={{ marginRight: '10px' }} />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav">
              <li className="nav-item">
                {/* Conditional rendering based on isLoggedIn state */}
                {isLoggedIn ? (
                  <Button className="nav-link active primary-button_logout" onClick={handleLogout} icon={<LogoutOutlined style={{paddingRight:'5px'}}/>} style={{background:'#008BF1'}}>
                    Logout
                  </Button>
                
            
                ) : (
                  <Link className="nav-link active" to="/login">Login</Link>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;

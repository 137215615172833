import React, { useState } from "react";
import { Form, Input, Button } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import Loader from "./Loader";
import config from "../config/config";

const Doclogin = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const response = await axios.post(`${config.API_URL}/api/doctor/login`, values);
      setLoading(false);

      if (response.data.success) {
        toast.success(response.data.message);
        localStorage.setItem("token", response.data.token); // Store the JWT token
        console.log("Stored token:", localStorage.getItem("token")); // Check token storage
        navigate("/home"); // Navigate to the desired route
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="authentication">
      <div className="authentication_form card p-3">
        <h1 className="card-title">Welcome Doctor</h1>
        {loading ? (
          <Loader />
        ) : (
          <Form layout="vertical" onFinish={onFinish}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: "Please input your email!" },
                { type: "email", message: "Please enter a valid email!" },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: "Please input your password!" }]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
            <Form.Item>
              <Button
                className="primary-button my-2 full-width-button"
                htmlType="submit"
                loading={loading} // Enable loading state while submitting
              >
                LOGIN
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </div>
  );
};

export default Doclogin;

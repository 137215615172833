import React from 'react'

export default function Error() {
  return (
    <div>
        <div class="alert alert-danger" role="alert">
        Something went wrong try again later
        </div>
    </div>
  )
}

import { Button, Form, Input, Select } from "antd";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "./Loader";
import config from "../config/config";

const { Option } = Select;

function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loginType, setLoginType] = useState("user"); // Default to user login

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const apiUrl = loginType === "user" ? `${config.API_URL}/api/user/login` : `${config.API_URL}/api/doctor/login`
      const response = await axios.post(apiUrl, values);
      // console.log("Response:", response.data);
      setLoading(false);

      if (response.data.success) {
        toast.success(response.data.message);
        localStorage.setItem("token", response.data.token); // Store token in local storage
        console.log("Stored token:", localStorage.getItem("token")); // Check token storage

        // Ensure token is correctly stored before navigating
        const storedToken = localStorage.getItem("token");
        if (storedToken) {
          if (loginType === "user") {
            navigate("/tabs"); // Navigate to user dashboard
          } else if (loginType === "doctor") {
            navigate("/docview"); // Navigate to doctor dashboard
          }
        } else {
          toast.error("Failed to store token");
        }
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="authentication">
      <div className="authentication_form card p-3">
        <h1 className="card-title">Welcome</h1>
        {loading ? (
          <Loader />
        ) : (
          <Form layout="vertical" onFinish={onFinish}>
            <Form.Item
              label="Login As"
              name="loginType"
              rules={[{ required: true, message: "Please select a login type!" }]}
            >
              <Select
                value={loginType}
                onChange={(value) => setLoginType(value)}
              >
                <Option value="user">User</Option>
                <Option value="doctor">Doctor</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: "Please input your email!" },
                { type: "email", message: "Please enter a valid email!" },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: "Please input your password!" }]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
            <Form.Item>
              <Button
                className="primary-button my-2 full-width-button"
                htmlType="submit"
                loading={loading}
              >
                LOGIN
              </Button>
            </Form.Item>
          </Form>
        )}
        {/* <Link to="/register" className="anchor mt-2">
          REGISTER
        </Link> */}
      </div>
    </div>
  );
}

export default Login;

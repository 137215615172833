// config.js
const dev = {
    API_URL: "http://localhost:5000",
  };
  
  const prod = {
    API_URL: "https://appointmentapi.drogapharmacy.com",
  };
  
  const config = process.env.NODE_ENV === "production" ? prod : dev;
  
  export default config;
  
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Button, Form, Select, TimePicker, Row, Col } from 'antd';
// import Loader from '../componenet/Loader'; 
// import Error from '../componenet/Error'; 
// import Doctor from '../componenet/Docto'; 
// import { useParams } from 'react-router-dom'; 
// import moment from 'moment'; 

// const { RangePicker } = TimePicker;
// const { Option } = Select;

// const Home = () => {
//   const { patientId } = useParams(); 
//   const [doctors, setDoctors] = useState([]);
//   const [branches, setBranches] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [dayOfWeek, setDayOfWeek] = useState(null);
//   const [timeRange, setTimeRange] = useState(null);
//   const [branch, setBranch] = useState(null);

//   useEffect(() => {
//     fetchDoctors();
//     fetchBranches();
//   }, []);

//   const fetchDoctors = async () => {
//     try {
//       setLoading(true);
//       const response = await axios.get('/api/doctor/getalldoctors');
//       setDoctors(response.data.doctors);
//       setLoading(false);
//     } catch (error) {
//       setError(error);
//       console.error('Error fetching doctors:', error);
//       setLoading(false);
//     }
//   };

//   const filterDoctors = async () => {
//     const params = {};
//     if (dayOfWeek) params.dayOfWeek = dayOfWeek;
//     if (timeRange) {
//       params.startTime = timeRange[0].format('HH:mm');
//       params.endTime = timeRange[1].format('HH:mm');
//     }
//     if (branch) params.branch = branch;

//     if (Object.keys(params).length === 0) {
//       fetchDoctors();
//       return;
//     }

//     console.log('Filter Params:', params); // Log the params for debugging

//     try {
//       setLoading(true);
//       const response = await axios.get('/api/doctor/filter', { params });
//       console.log('Filtered doctors:', response.data.doctors); // Log the response for debugging
//       setDoctors(response.data.doctors);
//       setLoading(false);
//     } catch (error) {
//       setError(error);
//       console.error('Error filtering doctors:', error);
//       setLoading(false);
//     }
//   };
  
//   const fetchBranches = async () => {
//     try {
//       const response = await axios.get('/api/branch/getallbranches');
//       setBranches(response.data); // Ensure correct data handling
//     } catch (error) {
//       console.error('Error fetching branches:', error);
//       setBranches([]); 
//     }
//   };

//   const handleDayChange = (value) => {
//     setDayOfWeek(value);
//   };

//   const handleTimeChange = (time) => {
//     setTimeRange(time);
//   };

//   const handleBranchChange = (value) => {
//     setBranch(value);
//   };

//   return (
//     <div className='container' style={{ background: '#fff' }}>
//       <div className='mt-5'>
//         <div className=''>
//           <Row gutter={16}>
//             <Col span={8}>
//               <Form.Item
//                 label="Day of Week"
//                 name="dayOfWeek"
//                 rules={[{ required: true, message: 'Day of Week is required' }]}
//               >
//                 <Select
//                   placeholder="Select day of week"
//                   onChange={handleDayChange}
//                 >
//                   <Option value="Monday">Monday</Option>
//                   <Option value="Tuesday">Tuesday</Option>
//                   <Option value="Wednesday">Wednesday</Option>
//                   <Option value="Thursday">Thursday</Option>
//                   <Option value="Friday">Friday</Option>
//                   <Option value="Saturday">Saturday</Option>
//                 </Select>
//               </Form.Item>
//             </Col>
//             <Col span={8}>
//               <Form.Item
//                 label="Time Range"
//                 name="timeRange"
//                 rules={[{ required: true, message: 'Time Range is required' }]}
//               >
//                 <RangePicker
//                   format="HH:mm"
//                   onChange={handleTimeChange}
//                 />
//               </Form.Item>
//             </Col>
//             <Col span={8}>
//               <Form.Item
//                 label="Branch"
//                 name="branch"
//                 rules={[{ required: true, message: 'Branch is required' }]}
//               >
//                 <Select
//                   placeholder="Select branch"
//                   onChange={handleBranchChange}
//                 >
//                   {branches.map(branch => (
//                     <Option key={branch._id} value={branch._id}>
//                       {branch.name}
//                     </Option>
//                   ))}
//                 </Select>
//               </Form.Item>
//             </Col>
//             <Col span={8}>
//               <Form.Item label=" " colon={false}> {/* Empty label to align with other form items */}
//                 <Button
//                   className="primary-button"
//                   type="primary"
//                   onClick={filterDoctors}
//                   style={{ marginTop: '4px' }} // Adjust margin to align with input fields
//                 >
//                   Filter Doctors
//                 </Button>
//               </Form.Item>
//             </Col>
//           </Row>
//         </div>
//       </div>
//       <div className='row justify-content-center g-5 mt-5 mb-5'>
//         {loading ? (
//           <Loader />
//         ) : doctors.length > 0 ? (
//           doctors.map((doctor) => (
//             <div key={doctor._id || doctor.id} className='col-md-4 col-sm-6 mt-2' >
//               <Doctor
//                 doctor={doctor}
//                 dayOfWeek={dayOfWeek}
//                 startTime={timeRange}
//                 patientId={patientId}
//                 branch={branch}
//               />
//             </div>
//           ))
//         ) : error ? (
//           <Error message={error.message} />
//         ) : (
//           <h1>No doctors found.</h1>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Home;



// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Button, Form, DatePicker, TimePicker, Row, Col,Select } from 'antd';
// import Loader from '../componenet/Loader'; 
// import Error from '../componenet/Error'; 
// import Doctor from '../componenet/Docto'; 
// import { useParams } from 'react-router-dom'; 
// import moment from 'moment'; 

// const { RangePicker: TimeRangePicker } = TimePicker;
// const { RangePicker: DateRangePicker } = DatePicker;

// const Home = () => {
//   const { patientId } = useParams(); 
//   const [doctors, setDoctors] = useState([]);
//   const [branches, setBranches] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [dateRange, setDateRange] = useState(null);
//   const [timeRange, setTimeRange] = useState(null);
//   const [branch, setBranch] = useState(null);

//   useEffect(() => {
//     fetchDoctors();
//     fetchBranches();
//   }, []);

//   const fetchDoctors = async () => {
//     try {
//       setLoading(true);
//       const response = await axios.get('/api/doctor/getalldoctors');
//       setDoctors(response.data.doctors);
//       setLoading(false);
//     } catch (error) {
//       setError(error);
//       console.error('Error fetching doctors:', error);
//       setLoading(false);
//     }
//   };

//   const filterDoctors = async () => {
//     const params = {};
//     if (dateRange) {
//       params.startDate = dateRange[0].format('YYYY-MM-DD');
//       params.endDate = dateRange[1].format('YYYY-MM-DD');
//     }
//     if (timeRange) {
//       params.startTime = timeRange[0].format('HH:mm');
//       params.endTime = timeRange[1].format('HH:mm');
//     }
//     if (branch) params.branch = branch;

//     if (Object.keys(params).length === 0) {
//       fetchDoctors();
//       return;
//     }

//     console.log('Filter Params:', params); // Log the params for debugging

//     try {
//       setLoading(true);
//       const response = await axios.get('/api/doctor/filter', { params });
//       console.log('Filtered doctors:', response.data.doctors); // Log the response for debugging
//       setDoctors(response.data.doctors);
//       setLoading(false);
//     } catch (error) {
//       setError(error);
//       console.error('Error filtering doctors:', error);
//       setLoading(false);
//     }
//   };
  
//   const fetchBranches = async () => {
//     try {
//       const response = await axios.get('/api/branch/getallbranches');
//       setBranches(response.data); // Ensure correct data handling
//     } catch (error) {
//       console.error('Error fetching branches:', error);
//       setBranches([]); 
//     }
//   };

//   const handleDateChange = (dates) => {
//     setDateRange(dates);
//   };

//   const handleTimeChange = (time) => {
//     setTimeRange(time);
//   };

//   const handleBranchChange = (value) => {
//     setBranch(value);
//   };

//   return (
//     <div className='container' style={{ background: '#fff' }}>
//       <div className='mt-5'>
//         <div className=''>
//           <Row gutter={16}>
//             <Col span={8}>
//               <Form.Item
//                 label="Date Range"
//                 name="dateRange"
//                 rules={[{ required: true, message: 'Date Range is required' }]}
//               >
//                 <DateRangePicker
//                   onChange={handleDateChange}
//                 />
//               </Form.Item>
//             </Col>
//             <Col span={8}>
//               <Form.Item
//                 label="Time Range"
//                 name="timeRange"
//                 rules={[{ required: true, message: 'Time Range is required' }]}
//               >
//                 <TimeRangePicker
//                   format="HH:mm"
//                   onChange={handleTimeChange}
//                 />
//               </Form.Item>
//             </Col>
//             <Col span={8}>
//               <Form.Item
//                 label="Branch"
//                 name="branch"
//                 rules={[{ required: true, message: 'Branch is required' }]}
//               >
//                 <Select
//                   placeholder="Select branch"
//                   onChange={handleBranchChange}
//                 >
//                   {branches.map(branch => (
//                     <Select.Option key={branch._id} value={branch._id}>
//                       {branch.name}
//                     </Select.Option>
//                   ))}
//                 </Select>
//               </Form.Item>
//             </Col>
//             <Col span={8}>
//               <Form.Item label=" " colon={false}> {/* Empty label to align with other form items */}
//                 <Button
//                   className="primary-button"
//                   type="primary"
//                   onClick={filterDoctors}
//                   style={{ marginTop: '4px' }} // Adjust margin to align with input fields
//                 >
//                   Filter Doctors
//                 </Button>
//               </Form.Item>
//             </Col>
//           </Row>
//         </div>
//       </div>
//       <div className='row justify-content-center g-5 mt-5 mb-5'>
//         {loading ? (
//           <Loader />
//         ) : doctors.length > 0 ? (
//           doctors.map((doctor) => (
//             <div key={doctor._id || doctor.id} className='col-md-4 col-sm-6 mt-2' >
//               <Doctor
//                 doctor={doctor}
//                 dateRange={dateRange}
//                 timeRange={timeRange}
//                 patientId={patientId}
//                 branch={branch}
//               />
//             </div>
//           ))
//         ) : error ? (
//           <Error message={error.message} />
//         ) : (
//           <h1>No doctors found.</h1>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Home;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Button, Form, DatePicker, TimePicker, Row, Col, Select } from 'antd';
// import Loader from '../componenet/Loader'; 
// import Error from '../componenet/Error'; 
// import Doctor from '../componenet/Docto'; 
// import { useParams } from 'react-router-dom'; 
// import moment from 'moment'; 

// const { RangePicker: TimeRangePicker } = TimePicker;

// const Home = () => {
//   const { patientId } = useParams(); 
//   const [doctors, setDoctors] = useState([]);
//   const [branches, setBranches] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [selectedDate, setSelectedDate] = useState(null);
//   const [timeRange, setTimeRange] = useState(null);
//   const [branch, setBranch] = useState(null);

//   useEffect(() => {
//     fetchDoctors();
//     fetchBranches();
//   }, []);

//   const fetchDoctors = async () => {
//     try {
//       setLoading(true);
//       const response = await axios.get('/api/doctor/getalldoctors');
//       setDoctors(response.data.doctors);
//       setLoading(false);
//     } catch (error) {
//       setError(error);
//       console.error('Error fetching doctors:', error);
//       setLoading(false);
//     }
//   };

//   const filterDoctors = async () => {
//     const params = {};
//     if (selectedDate) {
//       params.selectedDate = selectedDate.format('YYYY-MM-DD');
//     }
//     if (timeRange) {
//       params.startTime = timeRange[0].format('HH:mm');
//       params.endTime = timeRange[1].format('HH:mm');
//     }
//     if (branch) params.branch = branch;
  
//     if (Object.keys(params).length === 0) {
//       fetchDoctors();
//       return;
//     }
  
//     console.log('Filter Params:', params); // Log the params for debugging
  
//     try {
//       setLoading(true);
//       const response = await axios.get('/api/doctor/filter', { params });
//       const filteredDoctors = response.data.doctors;
//       console.log('Filtered doctors:', filteredDoctors); // Log the response for debugging
//       setDoctors(filteredDoctors);
//       setLoading(false);
//     } catch (error) {
//       setError(error);
//       console.error('Error filtering doctors:', error);
//       setLoading(false);
//       if (error.response) {
//         console.error('Backend response:', error.response.data);
//       }
//     }
//   };
  
  
  
//   const fetchBranches = async () => {
//     try {
//       const response = await axios.get('/api/branch/getallbranches');
//       setBranches(response.data); // Ensure correct data handling
//     } catch (error) {
//       console.error('Error fetching branches:', error);
//       setBranches([]); 
//     }
//   };

//   const handleDateChange = (date) => {
//     setSelectedDate(date);
//   };

//   const handleTimeChange = (time) => {
//     setTimeRange(time);
//   };

//   const handleBranchChange = (value) => {
//     setBranch(value);
//   };

//   return (
//     <div className='container' style={{ background: '#fff' }}>
//       <div className='mt-5'>
//         <div className=''>
//           <Row gutter={16}>
//             <Col span={8}>
//               <Form.Item
//                 label="Date"
//                 name="date"
//                 rules={[{ required: true, message: 'Date is required' }]}
//               >
//                 <DatePicker
//                   onChange={handleDateChange}
//                 />
//               </Form.Item>
//             </Col>
//             <Col span={8}>
//               <Form.Item
//                 label="Time Range"
//                 name="timeRange"
//                 rules={[{ required: true, message: 'Time Range is required' }]}
//               >
//                 <TimeRangePicker
//                   format="HH:mm"
//                   onChange={handleTimeChange}
//                 />
//               </Form.Item>
//             </Col>
//             <Col span={8}>
//               <Form.Item
//                 label="Branch"
//                 name="branch"
//                 rules={[{ required: true, message: 'Branch is required' }]}
//               >
//                 <Select
//                   placeholder="Select branch"
//                   onChange={handleBranchChange}
//                 >
//                   {branches.map(branch => (
//                     <Select.Option key={branch._id} value={branch._id}>
//                       {branch.name}
//                     </Select.Option>
//                   ))}
//                 </Select>
//               </Form.Item>
//             </Col>
//             <Col span={8}>
//               <Form.Item label=" " colon={false}> {/* Empty label to align with other form items */}
//                 <Button
//                   className="primary-button"
//                   type="primary"
//                   onClick={filterDoctors}
//                   style={{ marginTop: '4px' }} // Adjust margin to align with input fields
//                 >
//                   Filter Doctors
//                 </Button>
//               </Form.Item>
//             </Col>
//           </Row>
//         </div>
//       </div>
//       <div className='row justify-content-center g-5 mt-5 mb-5'>
//         {loading ? (
//           <Loader />
//         ) : doctors.length > 0 ? (
//           doctors.map((doctor) => (
//             <div key={doctor._id || doctor.id} className='col-md-4 col-sm-6 mt-2' >
//               <Doctor
//                 doctor={doctor}
//                 dateRange={selectedDate ? [selectedDate] : null}
//                 startTime={timeRange}
//                 patientId={patientId}
//                 branch={branch}
//               />
//             </div>
//           ))
//         ) : error ? (
//           <Error message={error.message} />
//         ) : (
//           <h1>No doctors found.</h1>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Home;


// Home.jsx

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Form, DatePicker, TimePicker, Row, Col, Select } from 'antd';
import Loader from '../componenet/Loader'; 
import Error from '../componenet/Error'; 
import Doctor from '../componenet/Docto'; 
import { useParams } from 'react-router-dom';
import config from "../config/config";
import moment from 'moment'; 

const { RangePicker: TimeRangePicker } = TimePicker;

const Home = () => {
  const { patientId } = useParams(); 
  const [doctors, setDoctors] = useState([]);
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [bookingTime, setBookingTime] = useState(null); // State for booking time
  const [timeRange, setTimeRange] = useState(null);
  const [branch, setBranch] = useState(null);

  useEffect(() => {
    fetchDoctors();
    fetchBranches();
  }, []);

  const fetchDoctors = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${config.API_URL}/api/doctor/getalldoctors`);
      setDoctors(response.data.doctors);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.error('Error fetching doctors:', error);
      setLoading(false);
    }
  };

  const filterDoctors = async () => {
    const params = {};
    if (selectedDate) {
      params.selectedDate = selectedDate.format('YYYY-MM-DD');
    }
    if (timeRange) {
      params.startTime = timeRange[0].format('HH:mm');
      params.endTime = timeRange[1].format('HH:mm');
    }
    if (branch) params.branch = branch;
  
    if (Object.keys(params).length === 0) {
      fetchDoctors();
      return;
    }
  
    console.log('Filter Params:', params); // Log the params for debugging
  
    try {
      setLoading(true);
      const response = await axios.get(`${config.API_URL}/api/doctor/filter`, { params });
      const filteredDoctors = response.data.doctors;
      console.log('Filtered doctors:', filteredDoctors); // Log the response for debugging
      setDoctors(filteredDoctors);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.error('Error filtering doctors:', error);
      setLoading(false);
      if (error.response) {
        console.error('Backend response:', error.response.data);
      }
    }
  };
  
  const fetchBranches = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/api/branch/getallbranches`);
      setBranches(response.data); // Ensure correct data handling
    } catch (error) {
      console.error('Error fetching branches:', error);
      setBranches([]); 
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTimeChange = (time) => {
    setTimeRange(time);
  };

  const handleBookingTimeChange = (time) => {
    setBookingTime(time);
  }; // Handler for booking time change

  const handleBranchChange = (value) => {
    setBranch(value);
  };

  return (
    <div className='container' style={{ background: '#fff' }}>
      <div className='mt-5'>
        <div className=''>
          <Row gutter={16}>
        
          <Col span={10}>
              <Form.Item
                label="Branch"
                name="branch"
                rules={[{ required: true, message: 'Branch is required' }]}
              >
                <Select
                  placeholder="Select branch"
                  onChange={handleBranchChange}
                >
                  {branches.map(branch => (
                    <Select.Option key={branch._id} value={branch._id}>
                      {branch.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Time Range"
                name="timeRange"
                rules={[{ required: true, message: 'Time Range is required' }]}
              >
                <TimeRangePicker
                  format="HH:mm"
                  onChange={handleTimeChange}
                />
              </Form.Item>
            </Col>
 
            <Col span={10}>
              <Form.Item
                label="Date"
                name="date"
                rules={[{ required: true, message: 'Date is required' }]}
              >
                <DatePicker
                  onChange={handleDateChange}
                />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label="Book Time"
                name="bookingTime"
                rules={[{ required: true, message: 'Booking Time is required' }]}
              >
                <TimePicker
                  format="HH:mm"
                  onChange={handleBookingTimeChange}
                />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item label=" " colon={false}> {/* Empty label to align with other form items */}
                <Button
                  className="primary-button"
                  type="primary"
                  onClick={filterDoctors}
                  style={{ marginTop: '4px' }} // Adjust margin to align with input fields
                >
                  Filter Doctors
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>
      <div className='row justify-content-center g-5 mt-5 mb-5'>
        {loading ? (
          <Loader />
        ) : doctors.length > 0 ? (
          doctors.map((doctor) => (
            <div key={doctor._id || doctor.id} className='col-md-4 col-sm-6 mt-2' >
              <Doctor
                doctor={doctor}
                dateRange={selectedDate ? [selectedDate] : null}
                bookingTime={bookingTime} 
                patientId={patientId}
                branch={branch}
              />
            </div>
          ))
        ) : error ? (
          <Error message={error.message} />
        ) : (
          <h1>No doctors found.</h1>
        )}
      </div>
    </div>
  );
};

export default Home;

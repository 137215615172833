// import React, { useState } from 'react';
// import axios from 'axios';
// import { Button, Modal } from 'antd';
// import { useNavigate } from 'react-router-dom';
// import avat from '../assets/avator.jpg';

// const Docto = ({ doctor, dayOfWeek, startTime, patientId }) => {
//   const navigate = useNavigate();
//   const [isModalVisible, setIsModalVisible] = useState(false);

//   const handleBookAppointment = async () => {
//     navigate(`/patientform?doctorId=${doctor._id}&dayOfWeek=${dayOfWeek}&startTime=${startTime[0].format('HH:mm')}`);
//   };

//   return (
//     <div className='row box_shadow_2' style={{ padding: '15px', width: '105%' }}>
//       <div className='col-md-4 d-flex justify-content-center align-items-center'>
//         <img src={avat} className='smalling' alt='Doctor' />
//       </div>
//       <div className='col-md-9'>
//         <h1 className='titel mt-2'>{doctor.fullname}</h1>
//         <div className='doc_det'>
//           <p>Emp Id: {doctor.empid}</p>
//           <p>Phone: {doctor.phonenumber}</p>
//           <p>Specialization: {doctor.specialization}</p>
//         </div>
//         <div style={{ float: 'left', marginTop: '10px' }}>
//           <Button className='primary-button' onClick={handleBookAppointment}>
//             Book Now
//           </Button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Docto;


// import React, { useState } from 'react';
// import axios from 'axios';
// import { Button, Modal, message } from 'antd';
// import { useNavigate } from 'react-router-dom';
// import avat from '../assets/avator.jpg';

// const Docto = ({ doctor, startTime, branch }) => {
//   const navigate = useNavigate();
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [modalMessage, setModalMessage] = useState('');
//   const [isBooked, setIsBooked] = useState(false);

//   const handleBookAppointment = async () => {
//     if (!startTime || !startTime[0]) {
//       message.error('Start time is not defined');
//       return;
//     }

//     try {
//       const startTimeFormatted = startTime[0].format('HH:mm');
//       const endTimeFormatted = startTime[0].clone().add(30, 'minutes').format('HH:mm');

//       console.log('Formatted startTime:', startTimeFormatted);
//       console.log('Formatted endTime:', endTimeFormatted);

//       const response = await axios.get(`/api/patient/doctors/${doctor._id}/availability`, {
//         params: {
        
//           startTime: startTimeFormatted,
//           endTime: endTimeFormatted,
//           branch
//         }
//       });

//       console.log('Server response:', response);

//       if (response.data.message === 'Doctor is available during this time') {
//         setIsBooked(true);
//         navigate(`/patientform?doctorId=${doctor._id}&startTime=${startTimeFormatted}`);
//       } else {
//         setIsModalVisible(true);
//         setModalMessage('Doctor is treating another patient during this time');
//       }
//     } catch (error) {
//       console.error('Error checking availability:', error);

//       if (error.response) {
//         console.error('Response data:', error.response.data);
//         console.error('Response status:', error.response.status);
//         console.error('Response headers:', error.response.headers);
//         message.error(`Error: ${error.response.data.message || 'Error occurred while checking availability.'}`);
//       } else if (error.request) {
//         console.error('Request data:', error.request);
//         message.error('No response received from the server.');
//       } else {
//         console.error('Error message:', error.message);
//         message.error('Error: ' + error.message);
//       }
//     }
//   };

//   const handleModalClose = () => {
//     setIsModalVisible(false);
//     setModalMessage('');
//   };

//   return (
//     <div className='row box_shadow_2' style={{ padding: '15px', width: '105%' }}>
//       <div className='col-md-4 d-flex justify-content-center align-items-center'>
//         <img src={avat} className='smalling' alt='Doctor' />
//       </div>
//       <div className='col-md-9'>
//         <h1 className='titel mt-2'>{doctor.fullname}</h1>
//         <div className='doc_det'>
//           <p>Emp Id: {doctor.empid}</p>
//           <p>Phone: {doctor.phonenumber}</p>
//           <p>Specialization: {doctor.specialization}</p>
//         </div>
//         <div style={{ float: 'left', marginTop: '10px' }}>
//           <Button
//             className='primary-button'
//             onClick={handleBookAppointment}
//             style={{ backgroundColor: isBooked ? 'green' : '', borderColor: isBooked ? 'green' : '' }}
//             disabled={isBooked}
//           >
//             {isBooked ? 'Booked' : 'Book Now'}
//           </Button>
//         </div>
//       </div>
//       <Modal
//         title="Doctor Unavailable"
//         visible={isModalVisible}
//         onOk={handleModalClose}
//         onCancel={handleModalClose}
//         okText="OK"
//       >
//         <p>{modalMessage}</p>
//       </Modal>
//     </div>
//   );
// };

// export default Docto;

// Doctor.jsx



// import React, { useState } from 'react';
// import axios from 'axios';
// import { Button, Modal, message } from 'antd';
// import { useNavigate } from 'react-router-dom';
// import avat from '../assets/avator.jpg';

// const Docto = ({ doctor, bookingTime, branch }) => {
//   const navigate = useNavigate();
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [modalMessage, setModalMessage] = useState('');
//   const [isBooked, setIsBooked] = useState(false);

//   const handleBookAppointment = async () => {
//     if (!bookingTime) {
//       message.error('Booking time is not defined');
//       return;
//     }

//     try {
//       const startTimeFormatted = bookingTime.format('HH:mm');
//       const endTimeFormatted = bookingTime.clone().add(30, 'minutes').format('HH:mm');

//       console.log('Formatted startTime:', startTimeFormatted);
//       console.log('Formatted endTime:', endTimeFormatted);

//       const response = await axios.get(`/api/patient/doctors/${doctor._id}/availability`, {
//         params: {
//           startTime: startTimeFormatted,
//           endTime: endTimeFormatted,
//           branch
//         }
//       });

//       console.log('Server response:', response);

//       if (response.data.message === 'Doctor is available during this time') {
//         setIsBooked(true);
//         navigate(`/patientform?doctorId=${doctor._id}&startTime=${startTimeFormatted}`);
//       } else {
//         setIsModalVisible(true);
//         setModalMessage('Doctor is treating another patient during this time');
//       }
//     } catch (error) {
//       console.error('Error checking availability:', error);

//       if (error.response) {
//         console.error('Response data:', error.response.data);
//         console.error('Response status:', error.response.status);
//         console.error('Response headers:', error.response.headers);
//         message.error(`Error: ${error.response.data.message || 'Error occurred while checking availability.'}`);
//       } else if (error.request) {
//         console.error('Request data:', error.request);
//         message.error('No response received from the server.');
//       } else {
//         console.error('Error message:', error.message);
//         message.error('Error: ' + error.message);
//       }
//     }
//   };

//   const handleModalClose = () => {
//     setIsModalVisible(false);
//     setModalMessage('');
//   };

//   return (
//     <div className='row box_shadow_2' style={{ padding: '15px', width: '105%' }}>
//       <div className='col-md-4 d-flex justify-content-center align-items-center'>
//         <img src={avat} className='smalling' alt='Doctor' />
//       </div>
//       <div className='col-md-9'>
//         <h1 className='titel mt-2'>{doctor.fullname}</h1>
//         <div className='doc_det'>
//           <p>Emp Id: {doctor.empid}</p>
//           <p>Phone: {doctor.phonenumber}</p>
//           <p>Specialization: {doctor.specialization}</p>
//         </div>
//         <div style={{ float: 'left', marginTop: '10px' }}>
//           <Button
//             className='primary-button'
//             onClick={handleBookAppointment}
//             style={{ backgroundColor: isBooked ? 'green' : '', borderColor: isBooked ? 'green' : '' }}
//             disabled={isBooked}
//           >
//             {isBooked ? 'Booked' : 'Book Now'}
//           </Button>
//         </div>
//       </div>
//       <Modal
//         title="Doctor Unavailable"
//         visible={isModalVisible}
//         onOk={handleModalClose}
//         onCancel={handleModalClose}
//         okText="OK"
//       >
//         <p>{modalMessage}</p>
//       </Modal>
//     </div>
//   );
// };

// export default Docto;


import React, { useState } from 'react';
import axios from 'axios';
import { Button, Modal, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import avat from '../assets/avator.jpg';
import config from '../config/config';

const Docto = ({ doctor, bookingTime, branch }) => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [isBooked, setIsBooked] = useState(false);
  const [isAvailable, setIsAvailable] = useState(true); // State to track doctor's availability

  const handleBookAppointment = async () => {
    if (!bookingTime) {
      message.error('Booking time is not defined');
      return;
    }
  
    try {
      const startTimeFormatted = bookingTime.format('HH:mm');
      const endTimeFormatted = bookingTime.clone().add(30, 'minutes').format('HH:mm');
  
      console.log('Formatted startTime:', startTimeFormatted);
      console.log('Formatted endTime:', endTimeFormatted);
  
      const response = await axios.get(`${config.API_URL}/api/patient/doctors/${doctor._id}/availability`, {
        params: {
          startTime: startTimeFormatted,
          endTime: endTimeFormatted,
          branch
        }
        
      });
  
      console.log('Server response:', response);
  
      if (response.data.message === 'Doctor is available during this time') {
        // Only change the button text to "Booked" here if the appointment is confirmed
        setIsBooked(true);
        navigate(`/patientform?doctorId=${doctor._id}&startTime=${startTimeFormatted}`);
      } else {
        setIsAvailable(false); // Doctor is not available
        setIsModalVisible(true);
        setModalMessage('Doctor is treating another patient during this time');
      }
    } catch (error) {
      console.error('Error checking availability:', error);
  
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
        message.error(` ${error.response.data.message || 'Error occurred while checking availability.'}`);
      } else if (error.request) {
        console.error('Request data:', error.request);
        message.error('No response received from the server.');
      } else {
        console.error('Error message:', error.message);
        message.error(' ' + error.message);
      }
    }
  };
  

  const handleModalClose = () => {
    setIsModalVisible(false);
    setModalMessage('');
  };

  return (
    <div className='row box_shadow_2' style={{ padding: '15px', width: '105%' }}>
      <div className='col-md-4 d-flex justify-content-center align-items-center'>
        <img src={avat} className='smalling' alt='Doctor' />
      </div>
      <div className='col-md-9'>
        <h1 className='titel mt-2'>{doctor.fullname}</h1>
        <div className='doc_det'>
          <p>Emp Id: {doctor.empid}</p>
          <p>Phone: {doctor.phonenumber}</p>
          <p>Specialization: {doctor.specialization}</p>
        </div>
        <div style={{ float: 'left', marginTop: '10px' }}>
          <Button
            className='primary-button'
            onClick={handleBookAppointment}
            style={{
              backgroundColor: isBooked ? 'green' : '',
              borderColor: isBooked ? 'green' : '',
            }}
            disabled={isBooked || !isAvailable} // Disable if booked or not available
          >
            {isBooked ? 'Booked' : 'Book Now'}
          </Button>
        </div>
      </div>
      <Modal
        title="Doctor Unavailable"
        visible={isModalVisible}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        okText="OK"
      >
        <p>{modalMessage}</p>
      </Modal>
    </div>
  );
};

export default Docto;





